import React, {Component, useEffect, useState, useRef} from "react";
import ButtonRounded from "../../../components/ButtonRounded";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ToggleButton from "../../../components/ToggleButton";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
// @ts-ignore
import { State } from "../../../reducers/reducer";
import { useSelector } from "react-redux";
import getAllocationPageData from "../../../api/sustainability/allocation/collaboration/get";
import {useHistory, useRouteMatch} from "react-router-dom";
import {startAllocation} from "../../../reducers/sustainability/allocation/multiStep";
import { hasRoadData, deleteRoadUseData } from "../../../api/sustainability/allocation/roadUse/data";
import { hasOffRoadData, deleteOffRoadUseData } from "../../../api/sustainability/allocation/offRoadUse/data";
import { hasMarineData, deleteMarineData } from "../../../api/sustainability/allocation/marineUse/data";
// @ts-ignore
import { toggleSimulation } from "../../../reducers/sustainability/main";
import { useDispatch } from "react-redux";
import resetAllocation from "../../../api/sustainability/allocation/roadUse/stepData";
import {resetAllocationOffroad} from "../../../api/sustainability/allocation/offRoadUse/stepData";
import getDownloadReport, {
    Tfiletype,
    Tmandate_type
} from "../../../api/sustainability/reports/getDownloadReport";
import ModalInfo from "../../../components/modal/ModalInfo";
import { getConfirmState, checkConfirmState } from "../../../api/sustainability/reports/getConfirmState";
import ModalConfirmDelivery from "../../../views/DashboardView/SustainabilityView/ReportsView/ModalConfirmDelivery";
import ModalDeleteVolumeInMandate from "../../../components/FilesTable/ModalDeleteVolumeInMandate";
import ButtonSquare from "../../../components/ButtonSquare";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
        },
        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        cardAllocation: {
            flex: "1", // Takes 2/3 of the available space
        },
        cardReport: {
            flex: "1", // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "11px",
                fontWeight: 700,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
        squareButton: {
            borderWidth: '6px',
            fontWeight: "bold",
            backgroundColor: 'transparent', // make background transparent
            // make border thicker
            borderColor: '#228B22',
        },
        clearfixAfter: {
            clear: 'both',
            content: '',
            display: 'block',
            height: 10,
        },
        containerArrowProgressbar: {
            fontFamily: "'Lato', sans-serif",
            width: '1050px',
            margin: '0 auto',
        },
        wrapper: {
            display: 'table-cell',
            height: '80px',
            verticalAlign: 'middle',
        },
        nav: {
            marginTop: '40px',
            },
        pullRight: {
            float: 'right',
        },
        link: {
            color: '#333',
            textDecoration: 'none',
            '&:active': {
                color: '#333',
            },
            '&:hover': {
                color: '#999',
            },
        },
        arrowSteps: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .step': {
                fontSize: '16px',
                textAlign: 'center',
                color: '#666',
                cursor: 'default',
                margin: '0 3px',
                padding: '25px 10px 10px 30px',
                minWidth: '250px',
                position: 'relative',
                backgroundColor: '#d9e3f7',
                userSelect: 'none',
                '&:after, &:before': {
                    content: '" "',
                    position: 'absolute',
                    top: '0',
                    width: 0,
                    height: 0,
                    right: '-16px',
                    borderTop: '19px solid transparent',
                    borderBottom: '17px solid transparent',
                    borderLeft: '17px solid #d9e3f7',
                    zIndex: 2,
                    transition: 'border-color 0.2s ease',
                },
                '&:before': {
                    left: 0,
                    borderLeft: '17px solid #fff',
                    zIndex: 0,
                },
                '&:first-child:before': {
                    border: 'none',
                },
                '&:first-child': {
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                },
                '& span': {
                    position: 'relative',
                    top: '-17px'
                },
                '&.done span:before': {
                    opacity: 1,
                    transition: 'opacity 0.3s ease 0.5s',
                },
                '&.volumes-added': {
                    color: '#fff',
                    backgroundColor: '#8fcc60',
                    '&:after': {
                        borderLeft: '17px solid #8fcc60',
                    },
                },
                '&.volumes-not-added': {
                    color: '#fff',
                    backgroundColor: '#ff4319',
                    '&:after': {
                        borderLeft: '17px solid #ff4319',
                    },
                },
                '&.allocation-completed': {
                    color: '#fff',
                    backgroundColor: '#8fcc60',
                    '&:after': {
                        borderLeft: '17px solid #8fcc60',
                    },
                },
                '&.allocation-not-started': {
                    color: '#fff',
                    backgroundColor: '#c2d1d9',
                    '&:after': {
                        borderLeft: '17px solid #c2d1d9',
                    },
                },
                '&.allocation-started': {
                    color: '#fff',
                    backgroundColor: '#ffd588',
                    '&:after': {
                        borderLeft: '17px solid #ffd588',
                    },
                },
                '&.report-submitted': {
                    color: '#fff',
                    backgroundColor: '#8fcc60',
                    '&:after': {
                        borderLeft: '17px solid #8fcc60',
                    },
                },
                '&.report-not-submitted': {
                    color: '#fff',
                    backgroundColor: '#ffd588',
                    '&:after': {
                        borderLeft: '17px solid #ffd588',
                    },
                },

            },
        },
    })
);


const ReportOverviewContainer: React.FC = () =>{
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const isSimulation = useSelector((state: State) => state.sustainability.main.isSimulation);
    const activePeriod = useSelector((state: State) => state.sustainability.main.activePeriod);

    const [isRoadUseToggleButtonEnabled, setIsRoadUseToggleButtonEnabled] = useState(true);
    const [isOffRoadToggleButtonEnabled, setIsoffRoadToggleButtonEnabled] = useState(true);
    const [isMarineToggleButtonEnabled, setIsMarineToggleButtonEnabled] = useState(true);

    const [isRoadUseJoint, setIsRoadUseJoint] = useState(false);
    const [isOffRoadJoint, setIsoffRoadJoint] = useState(false);
    const [isMarineJoint, setIsMarineJoint] = useState(false);

    const [areButtonsVisibleRoadUse, setAreButtonsVisibleRoadUse] = useState(false);
    const [areButtonsVisibleOffRoad, setAreButtonsVisibleOffRoad] = useState(false);
    const [areButtonsVisibleMarine, setAreButtonsVisibleMarine] = useState(false);
    const [marineDataId, setMarineDataId] = useState<any>(null);
    const [marineHasStep, setMarineHasStep] = useState<boolean>(false);
    const [roadUseId, setRoadUseId] = useState<any>(null);
    const [roadUseHasStep, setRoadUseHasStep] = useState<boolean>(false);
    const [offRoadUseId, setOffRoadUseId] = useState<any>(null);
    const [offRoadUseHasStep, setOffRoadUseHasStep] = useState<boolean>(false);
    const [confirmState, setConfirmState] = useState<boolean>(false);
    const [showModalDeleteRoaduse, setShowModalDeleteRoaduse] = useState<boolean>(false);
    const [showModalDeleteOffRoad, setShowModalDeleteOffRoad] = useState<boolean>(false);
    const [showModalDeleteMarine, setShowModalDeleteMarine] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [
    showConfirmDeliveryModal,
    setShowConfirmDeliveryModal,
] = useState<boolean>(false);

    const openErrorModal = (msg: string) => {
        setErrorMessage(msg);
        setShowErrorModal(true);
    };
    const closeErrorModal = () => {
        setErrorMessage("");
        setShowErrorModal(false);
    };

    const openConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(true);
    };

    const closeConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(false);
    };

    const openModalDeleteRoaduse = () => {
        setShowModalDeleteRoaduse(true);
    };
    const closeModalDeleteRoaduse = () => {
        setShowModalDeleteRoaduse(false);
    };

    const openModalDeleteOffRoad = () => {
        setShowModalDeleteOffRoad(true);
    };
    const closeModalDeleteOffRoad = () => {
        setShowModalDeleteOffRoad(false);
    };
    const openModalDeleteMarine = () => {
        setShowModalDeleteMarine(true);
    };
    const closeModalDeleteMarine = () => {
        setShowModalDeleteMarine(false);
    };
    const handleDraftReport = (mandateType:string) => {
        if (match) {
            history.push(`/sustainability/reports/${mandateType}`);
        }
    };

    const handleStartAllocation = async () => {
        const response = await resetAllocation();
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/roaduse/allocation`);
            }
        } else {
            alert("Error occured when reseting allocation");
        }

    };

    // Change the function name later
    const handleContinueAllocation = async () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/roaduse/allocation`);
        }
    };

    const handleOffRoadReport = async () => {
        const response = await resetAllocationOffroad();
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/offroad/allocation`);
            }
        }
    };
    const handleContinueAllocationOffroad = async () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/offroad/allocation`);
        }
    };
    const handleOffRoadVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/offroadvolumes`);
        }
    };
    const handleMarineVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/marinevolumes`);
        }
    };

    const handleRoadUseAddVolume = () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/allocation/roaduse`);
        }
    };

    const handleOffRoadAddVolume = () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/allocation/offroad`);
        }
    };

    const handleViewDraft = () => {
        if (match) {
            history.push(`/sustainability/reports`);
        }
    };

    const checkData = async () => {
        const roadUse = await hasRoadData();
        if (roadUse) {
            setRoadUseId(roadUse.id);
            setRoadUseHasStep(!!roadUse.last_active_step);
            setAreButtonsVisibleRoadUse(roadUse.mandate_enabled);
        }

        const offRoadUse = await hasOffRoadData();
        if (offRoadUse) {
            setOffRoadUseId(offRoadUse.id);
            setOffRoadUseHasStep(!!offRoadUse.last_active_step);
            setAreButtonsVisibleOffRoad(offRoadUse.mandate_enabled)
        }

        const marine = await hasMarineData();
        if (marine) {
            setMarineDataId(marine.id);
            setMarineHasStep(!!marine.last_active_step);
            setAreButtonsVisibleMarine(marine.mandate_enabled)
        }

        await checkConfirmationStatus();
    };
    const deleteRoaduseVolume = async () => {
        await deleteData("road-use", roadUseId)
    }
    const deleteOffRoadVolume = async () => {
        await deleteData("off-road-use", offRoadUseId)
    }
    const deleteMarineVolume = async () => {
        await deleteData("marine", marineDataId)
    }
    const deleteData = async ( type: string, id: any ) => {
        switch (type) {
            case "road-use" :
                await deleteRoadUseData(id);
                await resetAllocation();
                break;
            case "off-road-use" :
                await deleteOffRoadUseData(id, type);
                await resetAllocationOffroad();
                break;
            case "marine" :
                await deleteOffRoadUseData(id, type);
                // await deleteMarineData(id);
                break;
            default :
                console.log("no matched data");
        }
        await checkData();
        closeModalDeleteRoaduse(); // Close the modal after deletion
    };
    const isMounted = useRef(true);

    const handleDownload = async (type: Tfiletype, mandate_type: Tmandate_type) => {
        const response = await getDownloadReport({
            type,
            mandate_type,
            // @ts-ignore
            taxYear: activePeriod.year,
        });
        if (response === null) {
            openErrorModal(
                "Failed to download file: Possible errors: no file for selected year or other errors."
            );
        }

        if (response !== null) {
            // emulate file download by clicking on an <a /> link
            const url = window.URL.createObjectURL(response);
            const fileName =
                type === "draft_report_excel"
                    ? "sustainability-draft-report"
                    : type.indexOf("sub") > -1
                        ? "sustainability-subreport"
                        : "sustainability-report";
            const fileExt = type.indexOf("excel") > -1 ? "xlsx" : "json";

            const a = document.createElement("a");
            a.href = url;
            // @ts-ignore
            a.download = `${fileName}-${mandate_type}-${activePeriod.year}.${fileExt}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    };

    const handleConfirmationStatus = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            const res = await getConfirmState({ taxYear: activePeriod.year });
            if (res === null) {
                openErrorModal(
                    "Failed to confirm delivery. Possible errors: correct reporting year is not selected or other errors."
                );
            } else {
                openErrorModal(
                    "Delivery Confirmed."
                );
            }
            checkData();
        }
    };

    const checkConfirmationStatus = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            const confirmState = await checkConfirmState({taxYear: activePeriod.year});
            setConfirmState(confirmState);
        }
    };


    useEffect(() => {
        const params = {
            allocation: isSimulation ? "Simulation" : "Reporting",
            fulfilment: "own",
            // @ts-ignore
            taxYearId: activePeriod.id,
        };

        getAllocationPageData(params);
        checkData();

    }, [isSimulation, activePeriod]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            checkData();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);
    const currentYear = new Date().getFullYear();
    // @ts-ignore
    return (
        <div>
            <h1>Reporting Overview</h1>
            <div className={classes.cardContainer}>
                <Card className={`${classes.card} ${classes.cardReport}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.toggleCardTitle}>
                            <FormattedMessage id="allocationView.mainView.allocationMode"/>
                        </Typography>

                        <ToggleButton
                            labels={[`Reporting ${currentYear - 1}`, `Simulation ${currentYear}`]}
                            defaultLabelIndex={isSimulation ? 1 : 0}
                            handleChange={() => dispatch(toggleSimulation())}
                        />
                    </CardContent>
                </Card>
                <Card className={`${classes.card} ${classes.cardAllocation}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.cardTitle}>
                            <p>Report File and Actions</p>
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleDownload("report_excel","roaduse")}
                                >
                                    Draft Report (Excel)
                                </ButtonRounded>
                            </Grid>
                            <Grid item xs={4}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => handleDownload("report_excel","roaduse")}
                                >
                                    Tilde rep. files (ZIP)
                                </ButtonRounded>
                            </Grid>
                            <Grid item xs={4}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={openConfirmDeliveryModal}
                                    disabled={!!confirmState}
                                    // className={classes.btnDownloads}
                                >
                                    {confirmState ? (
                                        <FormattedMessage id="sustainabilityView.reportsView.confirmed" />
                                    ) : (
                                            <FormattedMessage id="loginView.confirm" />
                                        )}{" "}
                                    <FormattedMessage id="sustainabilityView.reportsView.delivery" />
                                </ButtonRounded>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>


            <Card className={`${classes.card}`}>
                {areButtonsVisibleRoadUse &&  <CardContent className={classes.cardContent}>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <h1>Road use mandate for 2023</h1>
                            <Grid item xs={9}>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <div className={classes.containerArrowProgressbar}>
                                    <div className={classes.wrapper}>
                                        <div className={`${classes.arrowSteps} ${classes.clearfixAfter}`}>
                                            <div className="step volumes-added">
                                                <span>Volumes added</span>
                                            </div>
                                            <div className="step allocation-completed">
                                                <span>Allocation Completed</span>
                                            </div>
                                            <div className="step report-not-submitted">
                                                <span>Report not submitted</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={()=>handleDraftReport('roaduse')}
                                        className={classes.buttonMargin}
                                        disabled={false}
                                    >
                                        View draft report
                                    </ButtonRounded>

                                </Grid>
                                <Grid item xs={3}>


                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownload("draft_report_excel","roaduse")}
                                        className={classes.buttonMargin}
                                        disabled={false}
                                    >
                                        Draft report (Excel)
                                    </ButtonRounded>

                                </Grid>
                                <Grid item xs={3}>


                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownload("report_excel","roaduse")}
                                        disabled={false}
                                    >
                                        Tilde report file
                                    </ButtonRounded>
                                </Grid>
                            </Grid>
                            {showModalDeleteRoaduse &&(
                                <>
                                    <ModalDeleteVolumeInMandate
                                        open={showModalDeleteRoaduse}
                                        handleDelete={async ()=>{
                                            // await deleteData("road-use", roadUseId);
                                            await deleteRoadUseData(roadUseId);
                                            await resetAllocation();
                                            await checkData();
                                            window.location.reload();
                                        }}
                                        onClose={closeModalDeleteRoaduse}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h5" className={classes.toggleCardTitle}>
                                <p>Allocation status</p>
                            </Typography>

                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#c2d1d9',
                                    backgroundColor: '#c2d1d9',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Joint fulfillment
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#8fcc60',
                                    backgroundColor: '#8fcc60',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Gasoline sub-mandate
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#8fcc60',
                                    backgroundColor: '#8fcc60',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Advanced sub-mandate
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#8fcc60',
                                    backgroundColor: '#8fcc60',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                General mandate
                            </ButtonSquare>

                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#8fcc60',
                                    backgroundColor: '#8fcc60',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Bio above mandate (required)
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#8fcc60',
                                    backgroundColor: '#8fcc60',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Bio above mandate
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </CardContent>}
            </Card>



            <Card className={`${classes.card}`}>
                { areButtonsVisibleOffRoad && <CardContent className={classes.cardContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <h1>Off-road mandate for 2023</h1>
                            <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                            {areButtonsVisibleOffRoad && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                <FormattedMessage id="allocationView.mainView.allocationMode"/>
                            </Typography>)}
                            {areButtonsVisibleOffRoad && (<Grid container spacing={2}>
                                <div className={classes.containerArrowProgressbar}>
                                    <div className={classes.wrapper}>
                                        <div className={`${classes.arrowSteps} ${classes.clearfixAfter}`}>
                                            <div className="step volumes-not-added">
                                                <span>Volumes not added</span>
                                            </div>
                                            <div className="step allocation-not-completed-faded">
                                                <span>Allocation not completed</span>
                                            </div>
                                            <div className="step report-not-submitted-faded">
                                                <span>Report not submitted</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={()=>handleDraftReport('offroad')}
                                        className={classes.buttonMargin}
                                        disabled={false}
                                    >
                                        View draft report
                                    </ButtonRounded>

                                </Grid>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownload("draft_report_excel","offroad")}
                                        className={classes.buttonMargin}
                                        disabled={false}
                                    >
                                        Draft report (Excel)
                                    </ButtonRounded>

                                </Grid>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownload("report_excel","offroad")}
                                        disabled={false}
                                    >
                                        Tilde report file
                                    </ButtonRounded>
                                </Grid>
                            </Grid>)}
                            {showModalDeleteOffRoad &&(
                                <>
                                    <ModalDeleteVolumeInMandate
                                        open={showModalDeleteOffRoad}
                                        handleDelete={()=>{
                                            deleteOffRoadVolume().then(() => {
                                                closeModalDeleteOffRoad(); // Close the modal after deletion
                                                window.location.reload(); // Reload the page after successful deletion
                                            });
                                        }}
                                        onClose={closeModalDeleteOffRoad}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h5" className={classes.toggleCardTitle}>
                                <p>Allocation status</p>
                            </Typography>

                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#c2d1d9',
                                    backgroundColor: '#c2d1d9',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Joint fulfillment
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#ff8787',
                                    backgroundColor: '#ff8787',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                General mandate
                            </ButtonSquare>

                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#ff8787',
                                    backgroundColor: '#ff8787',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Bio above mandate (required)
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#ff8787',
                                    backgroundColor: '#ff8787',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Bio above mandate
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </CardContent>}
            </Card>



            <Card className={`${classes.card}`}>
                { areButtonsVisibleMarine && <CardContent className={classes.cardContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <h1>Marine mandate for 2023</h1>
                            <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                            {areButtonsVisibleMarine && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                <FormattedMessage id="allocationView.mainView.allocationMode"/>
                            </Typography>)}
                            {areButtonsVisibleMarine && (<Grid container spacing={2}>
                                <div className={classes.containerArrowProgressbar}>
                                    <div className={classes.wrapper}>
                                        <div className={`${classes.arrowSteps} ${classes.clearfixAfter}`}>
                                            <div className="step volumes-not-added">
                                                <span>Volumes not added</span>
                                            </div>
                                            <div className="step allocation-not-completed-faded">
                                                <span>Allocation not completed</span>
                                            </div>
                                            <div className="step report-not-submitted-faded">
                                                <span>Report not submitted</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={()=>handleDraftReport('marine')}
                                        className={classes.buttonMargin}
                                        disabled={false}
                                    >
                                        View draft report
                                    </ButtonRounded>

                                </Grid>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownload("draft_report_excel","marine")}
                                        className={classes.buttonMargin}
                                        disabled={false}
                                    >
                                        Draft report (Excel)
                                    </ButtonRounded>

                                </Grid>
                                <Grid item xs={3}>

                                    <ButtonRounded
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => handleDownload("report_excel","marine")}
                                        disabled={false}
                                    >
                                        Tilde report file
                                    </ButtonRounded>
                                </Grid>
                            </Grid>)}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h5" className={classes.toggleCardTitle}>
                                <p>Mandate settings</p>
                            </Typography>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#c2d1d9',
                                    backgroundColor: '#c2d1d9',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Joint fulfillment
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#ff8787',
                                    backgroundColor: '#ff8787',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                General mandate
                            </ButtonSquare>

                            <ButtonSquare
                                variant="outlined"
                                color ="primary"
                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#ff8787',
                                    backgroundColor: '#ff8787',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Bio above mandate (required)
                            </ButtonSquare>
                            <ButtonSquare
                                variant="outlined"
                                color ="primary"

                                fullWidth
                                // onClick={handleRoadUseVolume}
                                className={classes.buttonMargin}
                                style={{
                                    borderWidth: '5px', // make border thicker
                                    borderColor: '#ff8787',
                                    backgroundColor: '#ff8787',
                                    paddingTop: '1px',
                                    paddingBottom: '1px',// set border color to green
                                    marginBottom: '10px',
                                    fontSize: '12px', // make text bold
                                    fontWeight: 'bold', // make text bold
                                    fontFamily: 'inherit', // keep the same text family
                                    color: '#464e63'
                                }}
                                disabled={roadUseId !== null}
                            >
                                Bio above mandate
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </CardContent>}

            </Card>

            <ModalInfo
                text={errorMessage}
                open={showErrorModal}
                onClose={closeErrorModal}
            />
            <ModalConfirmDelivery
                open={showConfirmDeliveryModal}
                onClose={closeConfirmDeliveryModal}
                onConfirm={() => {
                    closeConfirmDeliveryModal();
                    handleConfirmationStatus();
                }}
            />
        </div>
    );
};
export default ReportOverviewContainer;
