import React, {Fragment, useEffect, useState} from "react";
import clsx from "clsx";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import MarineCustomerReqPanel from "./MarineCustomerReqPanel";
import {hasRoadData} from "../../../../../../api/sustainability/allocation/roadUse/data";
import allocateCustomer from "../../../../../../api/card/allocationSteps/allocateCustomer";
import {useDispatch} from "react-redux";
import {allocateUpdateCustomerReq, changeStep} from "../../../../../../reducers/sustainability/allocation/multiStep";
import LoadingView from "./LoadingView";
import allocateConstruction from "../../../../../../api/sustainability/allocationSteps/allocateConstructionReports";
import allocationConstruction from "../../../../../../api/sustainability/allocationSteps/allocationConstruction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    btnContainer: {
      marginTop: theme.spacing(4),
    },
    btn: {
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      minWidth: "160px",
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    btnDark: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
  })
);

interface Biotemplate {
    id: number;
    biofuel_type: string;
    raw_material_origin: string;
    fuel_type: string;
    raw_material: string;
    double_counted_norway: string;
    reduction_in_climate_emission: number;
    storage_terminal: string;
    available_quantity: number;
    allocated_quantity: number;
    allocated_eq_quantity: number;
}

interface MarineCustomerRequirementsProps {
  onUpdateCustomerData: (data: any[]) => void;
}

const MarineCustomerRequirements: React.FC<MarineCustomerRequirementsProps> = ({
  onUpdateCustomerData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [availableVol, setAvailableVol] = useState({});
  const [stepData, setStepData] = useState(
        {
            status: "Not Complete",
            isComplete: false,
            data: [],
            allocated: {},
            allocated_sorted: {},
            sumAllocated: {},
            requirement_quantities: {},
            available_quantities_part1: {},
            available_quantities_part2: {},
        }
    );
  const [dataFetching, setDataFetching] = useState<boolean>(false);

  const getStepData = async () => {
        setDataFetching(true);
        const response = await allocateConstruction();
        if (response && response.valid) {
            // @ts-ignore
            if (response.customers) {
                // @ts-ignore
                setCustomerData(response.customers);
            } else {
                alert("No customer data found.");
            }
        } else {
            alert("Some error occured when fetching data.");
        }
        setDataFetching(false);
        // const response = await dispatch(changeStep(6));
        // if (response) {
        //     console.error(response);
        //     // @ts-ignore
        //     setStepData(response);
        // }
    };

  const getAllocatedSorted = (data: any[]) => {
        const types = ["fame", "hvo_hro", "etanol", "nafta"];
        let allocated: any = {};
        Object.keys(data).length > 0 &&
        data.map((cust: any, cust_idx: number) => {
            if (cust && cust.customer_requirements) {
                return cust.customer_requirements.map((req: any, req_idx: number) =>
                    types.map((type: any) => {
                        req["biotemplates_" + type].map((row: any, idx: number) => {
                            if (!allocated[cust.id]) {
                                allocated = { ...allocated, [cust.id]: {} };
                            }
                            if (!allocated[cust.id][req.id]) {
                                allocated = {
                                    ...allocated,
                                    [cust.id]: { ...allocated[cust.id], [req.id]: {} },
                                };
                            }
                            if (!allocated[cust.id][req.id][type]) {
                                allocated = {
                                    ...allocated,
                                    [cust.id]: {
                                        ...allocated[cust.id],
                                        [req.id]: {
                                            ...allocated[cust.id][req.id],
                                            [type]: {},
                                        },
                                    },
                                };
                            }

                            allocated = {
                                ...allocated,
                                [cust.id]: {
                                    ...allocated[cust.id],
                                    [req.id]: {
                                        ...allocated[cust.id][req.id],
                                        [type]: {
                                            ...allocated[cust.id][req.id][type],
                                            [row.id]: row.allocated_ton_quantity,
                                        },
                                    },
                                },
                            };
                        });
                    })
                );
            }
        });
        return allocated;
    };

  const getSumAllocated = (data: any[], newAllocatedSorted: any[]) => {
        let sumAllocatedAll: any = {};

        Array.isArray(data) &&
        data.map((cust: any) => {
            if (cust && cust.customer_requirements) {
                return cust.customer_requirements.map((req: any) => {
                    let sumAllocated: any = {
                        fame: 0,
                        hvo_hro: 0,
                        etanol: 0,
                        nafta: 0,
                    };
                    let total = 0;
                    Object.keys(sumAllocated).map(type => {
                        let sumType = 0;
                        newAllocatedSorted[cust.id] &&
                        newAllocatedSorted[cust.id][req.id] &&
                        newAllocatedSorted[cust.id][req.id][type] &&
                        Object.keys(newAllocatedSorted[cust.id][req.id][type]).map(row_id =>
                                (sumType = +(
                                    sumType +
                                    parseFloat(
                                        newAllocatedSorted[cust.id][req.id][type][row_id]
                                    )
                                ).toFixed(2))
                        );
                        sumAllocated = { ...sumAllocated, [type]: sumType };
                        total = +(total + sumType).toFixed(2);
                    });
                    sumAllocated = { ...sumAllocated, ["total"]: total };
                    sumAllocatedAll = {
                        ...sumAllocatedAll,
                        [cust.id]: {
                            ...sumAllocatedAll[cust.id],
                            [req.id]: sumAllocated,
                        },
                    };
                });
            }
        });
        return sumAllocatedAll;
    };

  const isCompleteStep = (data: any[], newSumAllocated: any[]) => {
        let isComplete = true;
        Object.keys(data).length > 0 &&
        data.map((cust: any, cust_idx: number) => {
            if (data[cust_idx] && data[cust_idx].customer_requirements) {
                data[cust_idx].customer_requirements.map(
                    (req: any, req_idx: number) => {
                        if (
                            newSumAllocated[cust.id][req.id].fame <
                            req.required_emission_reduction_fame ||
                            newSumAllocated[cust.id][req.id].hvo_hro <
                            req.required_emission_reduction_hvo_hro ||
                            newSumAllocated[cust.id][req.id].etanol <
                            req.required_emission_reduction_etanol ||
                            newSumAllocated[cust.id][req.id].nafta <
                            req.required_emission_reduction_nafta
                        ) {
                            isComplete = false;
                        }
                    }
                );
            }
        });
        return isComplete;
    };

  const statusStep = (data: any[], newSumAllocated: any[]) => {
        let status = "Complete";
        Object.keys(data).length > 0 &&
        data.map((cust: any, cust_idx: number) => {
            if (data[cust_idx] && data[cust_idx].customer_requirements) {
                data[cust_idx].customer_requirements.map(
                    (req: any, req_idx: number) => {
                        if (
                            newSumAllocated[cust.id][req.id].fame <
                            req.required_emission_reduction_fame ||
                            newSumAllocated[cust.id][req.id].hvo_hro <
                            req.required_emission_reduction_hvo_hro ||
                            newSumAllocated[cust.id][req.id].etanol <
                            req.required_emission_reduction_etanol ||
                            newSumAllocated[cust.id][req.id].nafta <
                            req.required_emission_reduction_nafta
                        ) {
                            status = "Not Complete";
                            return;
                        }
                        if (
                            newSumAllocated[cust.id][req.id].fame >
                            req.required_emission_reduction_fame ||
                            newSumAllocated[cust.id][req.id].hvo_hro >
                            req.required_emission_reduction_hvo_hro ||
                            newSumAllocated[cust.id][req.id].etanol >
                            req.required_emission_reduction_etanol ||
                            newSumAllocated[cust.id][req.id].nafta >
                            req.required_emission_reduction_nafta
                        ) {
                            status = "Overallocated";
                        }
                    }
                );
            }
        });
        return status;
    };

  const allocateUpdatedCustomerReq = async (id: number,
                                              index: number,
                                              value: number,
                                              requirement: { id: number; index: number; type: string },
                                              customer: { id: number; index: number }) => {
        const req = requirement;
        const cust = customer;
        const activeStep = stepData;
        const {
            // @ts-ignore
            [req.id]: oldAllocatedRequirement,
            ...allocatedRestRequirements
        } = activeStep.allocated;

        const { [id]: oldAllocated, ...allocatedRest } =
            activeStep.allocated && activeStep.allocated[req.id]
                ? activeStep.allocated[req.id]
                : {};

        // Get AllocatedSorted

        const {
            // @ts-ignore
            [cust.id]: oldAllocatedSortedCustomer,
            ...allocatedRestSortedCustomers
        } = activeStep.allocated_sorted ? activeStep.allocated_sorted : {};

        const {
            [req.id]: oldAllocatedSortedRequirement,
            ...allocatedRestSortedRequirements
        } =
            activeStep.allocated_sorted && activeStep.allocated_sorted[cust.id]
                ? activeStep.allocated_sorted[cust.id]
                : {};

        const { [id]: oldAllocatedSorted, ...allocatedRestSorted } =
            activeStep.allocated_sorted &&
            activeStep.allocated_sorted[cust.id] &&
            activeStep.allocated_sorted[cust.id][req.id]
                ? activeStep.allocated_sorted[cust.id][req.id]
                : {};

        const {
            [id]: oldAllocatedSortedType,
            ...allocatedRestSortedType
        } =
            activeStep.allocated_sorted &&
            activeStep.allocated_sorted[cust.id] &&
            activeStep.allocated_sorted[cust.id][req.id] &&
            activeStep.allocated_sorted[cust.id][req.id][req.type]
                ? activeStep.allocated_sorted[cust.id][req.id][req.type]
                : {};

        // calculate available quantities

        const {
            // @ts-ignore
            [id]: oldAvailable,
            ...availableRest
        } = stepData.available_quantities_part1;

        // @ts-ignore
        let newAllocatedValue = value ? parseFloat(value) : 0;
        if (newAllocatedValue < 0) { newAllocatedValue = 0; }

        // calculate available quantities
        const diffAllocated = oldAllocatedSorted
            ? newAllocatedValue - oldAllocatedSorted
            : newAllocatedValue - 0;

        const newAvailableValue = oldAvailable - diffAllocated;
        // TO DO - uncomment to limit max allocated to available quantity
        /* if (newAvailableValue < 0) {
          newAllocatedValue += newAvailableValue;
          newAvailableValue = 0;
        } */

        const newAvailable = { [id]: newAvailableValue };

        const newAllocatedSorted = {
            [cust.id]: {
                ...allocatedRestSortedRequirements,
                [req.id]: {
                    ...allocatedRestSorted,
                    [req.type]: {
                        ...allocatedRestSortedType,
                        [id]: newAllocatedValue,
                    },
                },
            },
        };

        const newAllocatedSortedComplete = {
            ...allocatedRestSortedCustomers,
            ...newAllocatedSorted,
        };

        const newAllocated =
            value !== null
                ? {
                    [req.id]: {
                        ...allocatedRest,
                        [id]: newAllocatedValue,
                    },
                }
                : Object.keys(allocatedRest).length > 0
                    ? {
                        [req.id]: {
                            ...allocatedRest,
                        },
                    }
                    : {};

        const newAllocatedComplete = {
            ...allocatedRestRequirements,
            ...newAllocated,
        };

        const getSumAllocated = () => {
            let sumAllocated: any = { fame: 0, hvo_hro: 0, etanol: 0, nafta: 0 };
            let total = 0;
            Object.keys(sumAllocated).map(type => {
                let sumType = 0;
                newAllocatedSorted[cust.id][req.id][type] &&
                Object.keys(newAllocatedSorted[cust.id][req.id][type]).map(row_id =>
                        (sumType = +(
                            sumType +
                            parseFloat(newAllocatedSorted[cust.id][req.id][type][row_id])
                        ).toFixed(2))
                );
                sumAllocated = { ...sumAllocated, [type]: sumType };
                total = +(total + sumType).toFixed(2);
            });
            sumAllocated = { ...sumAllocated, ["total"]: total };
            return sumAllocated;
        };

        const newSumAllocated = {
            ...activeStep.sumAllocated,
            [cust.id]: {
                ...activeStep.sumAllocated[cust.id],
                [req.id]: getSumAllocated(),
            },
        };

        // Determine if complete

        const isComplete = () => {
            let isComplete = true;
            const data = stepData.data;
            Object.keys(data).length > 0 &&
            data.map((cust: any, cust_idx: number) => {
                // @ts-ignore
                if (data[cust_idx] && data[cust_idx].customer_requirements) {
                    // @ts-ignore
                    data[cust_idx].customer_requirements.map(
                        (req: any, req_idx: number) => {
                            if (
                                newSumAllocated[cust.id][req.id].fame <
                                req.required_emission_reduction_fame ||
                                newSumAllocated[cust.id][req.id].hvo_hro <
                                req.required_emission_reduction_hvo_hro ||
                                newSumAllocated[cust.id][req.id].etanol <
                                req.required_emission_reduction_etanol ||
                                newSumAllocated[cust.id][req.id].nafta <
                                req.required_emission_reduction_nafta
                            ) {
                                isComplete = false;
                            }
                        }
                    );
                }
            });
            return isComplete;
        };

        const statusStep = () => {
            let status = "Complete";
            const data = stepData.data;
            Object.keys(data).length > 0 &&
            data.map((cust: any, cust_idx: number) => {
                // @ts-ignore
                if (data[cust_idx] && data[cust_idx].customer_requirements) {
                    // @ts-ignore
                    data[cust_idx].customer_requirements.map(
                        (req: any, req_idx: number) => {
                            if (
                                newSumAllocated[cust.id][req.id].fame <
                                req.required_emission_reduction_fame ||
                                newSumAllocated[cust.id][req.id].hvo_hro <
                                req.required_emission_reduction_hvo_hro ||
                                newSumAllocated[cust.id][req.id].etanol <
                                req.required_emission_reduction_etanol ||
                                newSumAllocated[cust.id][req.id].nafta <
                                req.required_emission_reduction_nafta
                            ) {
                                status = "Not Complete";
                                return;
                            }
                            if (
                                newSumAllocated[cust.id][req.id].fame >
                                req.required_emission_reduction_fame ||
                                newSumAllocated[cust.id][req.id].hvo_hro >
                                req.required_emission_reduction_hvo_hro ||
                                newSumAllocated[cust.id][req.id].etanol >
                                req.required_emission_reduction_etanol ||
                                newSumAllocated[cust.id][req.id].nafta >
                                req.required_emission_reduction_nafta
                            ) {
                                status = "Overallocated";
                            }
                        }
                    );
                }
            });
            return status;
        };

        setStepData(prevData => ({
            ...prevData,
            isComplete: isComplete(),
            status: statusStep(),
            allocated: newAllocatedComplete,
            allocated_sorted: newAllocatedSortedComplete,
            sumAllocated: newSumAllocated,
        }));

    };

  useEffect(() => {
        const newAllocatedSorted = getAllocatedSorted(customerData);
        // @ts-ignore
        setStepData(prevData => ({
            ...prevData,
            isComplete: isCompleteStep(customerData, getSumAllocated(customerData, newAllocatedSorted)),
            status: statusStep(customerData, getSumAllocated(customerData, newAllocatedSorted)),
            data: customerData,
            allocated_sorted: newAllocatedSorted,
            sumAllocated: getSumAllocated(customerData, newAllocatedSorted),
        }));
    }, [customerData]);

  useEffect(() => {
        // @ts-ignore
        onUpdateCustomerData(stepData);
    }, [stepData]);

  useEffect(() => {
        getStepData();
    }, []);
  return (
    <div className={classes.root}>
        {dataFetching ? (
            <LoadingView text={"Data is getting prepared"} />
        ) : (
            <>

                {
                    // @ts-ignore
                    Array.isArray(stepData.data) &&
                // @ts-ignore
                stepData.data.map((customer: any, index: any) => (
                    <Fragment key={index}>
                        <MarineCustomerReqPanel
                            stepData={stepData}
                            data={customer}
                            available_quantities={availableVol}
                            customer={{ id: customer.id, index }}
                            allocateUpdateCustomerReq={allocateUpdatedCustomerReq}
                        />
                    </Fragment>
                ))}
            </>
        )}
    </div>
  );
};

export default MarineCustomerRequirements;
